import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Typography = makeShortcode("Typography");
const SingleImage = makeShortcode("SingleImage");
const ImageAccordion = makeShortcode("ImageAccordion");
const Paper = makeShortcode("Paper");
const Grid = makeShortcode("Grid");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2 {...{
      "id": "supporting-online-learning"
    }}>{`Supporting online learning`}</h2>
    <p>{`How does your teen cope with online learning?
Spend a moment reflecting on your teen, what they like or dislike about online learning, and what supports they might need while learning from home.`}</p>
    <p>{`Now consider this scenario. Amy’s school has moved to online learning during the latest lockdown. She is trying to study for Year 8 science, taking notes while watching a pre-recorded online class.`}</p>
    <Typography color="primary" mdxType="Typography">
  Click the numbers below the image to see different ways you can support your teen during online learning.
    </Typography>
    <SingleImage smallGridSize={8} gridSize={6} src="/images/m2/10-teen-online-learning.png" alt="Teen Online Learning" mdxType="SingleImage" />
    <ImageAccordion id="pandemicSupportOnlineLearning1" index="1" title="Adjust your expectations" description={<div>
      Keep in mind that your teen might experience anxiety about moving to online learning, even if they’ve done it
      before, and fatigue due to increased screen time. Because of this, <b>adjust your expectations</b>. Don’t expect
      them to engage in the same amount of work as they usually would at school.
    </div>} hasNumber={true} mdxType="ImageAccordion" />
    <ImageAccordion id="pandemicSupportOnlineLearning2" index="2" title="Supportive space for learning" description={<div>
      As much as you can, try to make your home a <b>supportive space for learning</b>, e.g. providing a quiet,
      distraction-free place to work and the required technology.
    </div>} hasNumber={true} mdxType="ImageAccordion" />
    <ImageAccordion id="pandemicSupportOnlineLearning3" index="3" title="Individual learning needs" description={<div>
      Consider your teen’s <b>individual learning needs</b>. Adjust how much support and structure you provide them
      based on their needs.
    </div>} hasNumber={true} mdxType="ImageAccordion" />
    <ImageAccordion id="pandemicSupportOnlineLearning4" index="4" title="Information from the school" description={<div>
      Ask your teen whether they are getting any <b>information from the school</b> that would be helpful for you to
      know.
    </div>} hasNumber={true} mdxType="ImageAccordion" />
    <ImageAccordion id="pandemicSupportOnlineLearning5" index="5" title="Create a routine" description={<div>
      Work with your teen to <b>plan their ideal routine</b> to get their schoolwork done while keeping themselves
      physically and mentally fit.
    </div>} hasNumber={true} mdxType="ImageAccordion" />
    <ImageAccordion id="pandemicSupportOnlineLearning6" index="6" title="Rewards" description={<div>
      Help your teen <b>reward themselves</b> after doing a session of homework. You can also <b>reward your teen</b>{' '}
      after getting through tough days by doing something fun together (e.g. eating a favourite meal or watching their
      favourite movie together).
    </div>} hasNumber={true} mdxType="ImageAccordion" />
    <ImageAccordion id="pandemicSupportOnlineLearning7" index="7" title="If your teen has a learning disability" description={<div>
      <b>If you teen has a learning disability</b>, ask their teachers to help you decide which key skills to focus on
      and how you can support your teen’s learning.
    </div>} hasNumber={true} mdxType="ImageAccordion" />
    <Paper elevation={0} style={{
      backgroundColor: '#bce2e3',
      borderRadius: '20px'
    }} mdxType="Paper">
  <Grid container alignItems="center" spacing={1} mdxType="Grid">
    <Grid item xs={2} align="center" mdxType="Grid">
      <ImportContactsIcon color="primary" style={{
            fontSize: 40
          }} mdxType="ImportContactsIcon" />
    </Grid>
    <Grid item xs={10} mdxType="Grid">
      <b>Unsure? Reach out to your teen’s teachers or school for support:</b>
    </Grid>
  </Grid>
  <ul>
    <li>Ask how involved you should be in your teen’s schoolwork and/or what work should take priority.</li>
    <li>
      Ask about the school’s available supports or resources (e.g. academic support, electronic devices, mental health
      support).
    </li>
    <li>Ask how you can support your teen’s learning process.</li>
    <li>
      If you’re concerned about how your teen is coping or keeping up at school, reach out to their teachers or other
      school staff.
    </li>
    <li>Keep in touch with your teen’s teachers. Include your teen in any discussions about their needs.</li>
    <li>
      If your teen usually receives additional services (e.g., mental health support, academic support), ask how these
      will be handled during periods of online learning.
    </li>
  </ul>
    </Paper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      